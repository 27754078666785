* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.photo-topic {
  text-align: center;
  color: white;
  font-family: var(--font-family);
  margin-top: 150px;
  margin-bottom: 60px;
}
.photo-topic h5 {
  font-weight: 600;
  font-size: 2rem;
}
.photos-container .item {
  padding-left: 0 !important;
  padding-top: 0 !important;
}

.css-mhc70k-MuiGrid-root > .MuiGrid-item {
  padding-top: 0;
}
.ketan-photos img {
  width: 100%;
  min-height: 300px;
  max-height: 300px;
  object-fit: cover;
}
.ketan-photos {
  margin: 4px;
}
.learn-button button {
  background-color: transparent;
  outline: none;
  color: #fff;
  padding: 10px 20px;
  border: 1px solid #7a6d6d;
  border-radius: 12px;
  transition: all ease-in-out 0.5s;
}

.learn-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
}

.learn-button button:hover {
  background-color: #fff;
  color: black;
  transition: all ease-in-out 0.5s;
}
