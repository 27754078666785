* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.footer-container {
  background: url("../../public/image/contact-image.png") no-repeat;
  background-size: cover;
  background-position: top;
  position: relative;
  padding: 75px 0;
  margin-top: 75px;
}
.image-footer img {
  background-image: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 500px;
  object-fit: cover;
  margin-top: 100px;
  color: transparent;
}

.footer-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;
  text-align: center;
  color: white;
  flex-wrap: wrap;
  margin: 80px 0 65px 0;
}

.footer-container {
  position: relative;
}

.contact-section p {
  color: white;
  text-align: center;
  font-weight: 600;
  font-size: 32px;
}

.footer-bottom button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  background-color: transparent;
  outline: none;
  color: white;
  border: 1px solid gray;
  padding: 13px 25px;
  border-radius: 10px;
  margin: 0 auto;
  transition: all ease-in-out 0.5s;
}

.footer-icon-wrap p {
  font-size: 14px;
  color: gray;
  margin-top: 30px;
  line-height: 2px;
}
.footer-icon-wrap span {
  font-size: 18px;
  font-weight: 500;
}
.footer-icon-image {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.footer-icon-image a {
  color: white;
}
.footer-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-top: 30px;
}
.footer-copyright {
  color: white;
  margin-top: 20px;
  font-family: var(--font-family);
  font-size: 14px;
}
.term-policy {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  gap: 40px;
  margin-top: 20px;
  font-family: var(--font-family);
  font-size: 14px;
}
.footer-bottom button:hover {
  background-color: white;
  color: black;
  transition: all ease-in-out 0.5s;
}

.footer-copyright span {
  margin-bottom: 68px;
  display: block;
}
