* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.Loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}


