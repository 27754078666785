@import url("https://fonts.googleapis.com/css2?family=Gabarito:wght@500&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.banner-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.name-ketan {
  color: white;

  top: 180px;
  font-size: 35px;
}
.banner-container img {
  width: 100%;
}

.about-ketan {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-family: var(--font-family);
  margin-top: 50px;
}
.about-ketan h1 {
  font-size: 40px;
  font-weight: 600;
}
.visit-button  {
  padding: 16px 32px;
  margin-top: 20px;
  background-color: transparent;
  border-radius: 10px;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  gap: 20px;
  align-items: center;
  outline: none;
  border: 1px solid rgb(59, 59, 59);
}
a:hover {
  text-decoration: none;
}
.name-ketan h1 {
  color: transparent;
  -webkit-text-stroke: 1px white;
  background: url(back.png);
  -webkit-background-clip: text;
  background-position: -43px 12px;
  animation: back 20s linear infinite;

  text-align: center;
  font-size: 80px;
}

@keyframes back {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}
