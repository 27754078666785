* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.image-videoslider img {
  width: 100%;
  height: 800px;
  object-fit: cover;
}
.image-videoslider {
  height: 100%;
}
.videoslider-container {
  position: relative;
  margin-top: 60px;
}
.offical {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 60px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text-offical {
  text-align: center;
  font-family: var(--font-family);
}
.text-offical h5 {
  font-size: 23px;
}
.text-offical p {
  font-size: 17px;
  color: rgb(148, 146, 146);
  margin-top: 10px;
}
.offical-icon {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 20px;
}

.icon {
  font-size: 30px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  color: #fff;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.offical-icon a {
  color: white;
}

.newrelease-title {
  display: flex;
  justify-content: space-between;
  color: white;
  margin-top: 50px;
  align-items: center;
}

.image-newrelease-container {
  display: flex;
}
.newrelease-title h4 {
  font-size: 24px;
  font-family: var(--font-family);
  font-weight: 500;
}
.icon-arrow {
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
}
.image-newrelease-container {
  display: flex;
  align-items: center;

  gap: 20px;
  color: white;
  border: 1px solid rgb(26, 25, 25);
  margin: 30px;
  background-color: rgb(26, 25, 25);
  border-radius: 5px;
}

.image-newrelease-container p {
  margin-bottom: 0;
}

.popular-title h4 {
  font-size: 24px;
  font-weight: 500;
}

.arrow-icon {
  display: flex;
  align-items: center;
  gap: 20px;
}

.popular-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  color: white;
  margin-top: 60px;
}

.slick-slide img {
  display: inline-block;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  width: 100px;
}

.slick-prev:before {
  content: "";
}
.slick-next:before,
[dir="rtl"] .slick-prev:before {
  content: "";
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: white;
  font-size: medium;
}
.slick-dots li button::before {
  color: rgba(255, 255, 255, 0.5);

  margin-top: 20px;
}
.icon-play-video {
  margin-left: auto;
  margin-right: 20px;
  font-size: 20px;
}

/* @media screen and (max-width: 992px) {
  .icon-play-video {
    font-size: 20px;
    margin-left: 10px;
  }
} */

/* @media screen and (max-width: 792px) {
  .image-newrelease-container {
    display: flex;
    align-items: center;
    gap: 20px; 
   margin: 10px;
  }
  .icon-play-video {
    font-size: 15px;
  }
  .image-newrelease-container img {
    width: 70px;
  }
} */
/* 
@media screen and (max-width: 767px) {
  .image-newrelease-container {
    display: flex;
    align-items: center;
    gap: 5px; 
   margin: 10px;
  }
  .icon-play-video {
    font-size: 20px;
  }
  .image-newrelease-container img {
    width: 90px;
  }
}
@media screen and (max-width: 668px) {
  .image-newrelease-container {
    display: flex;
    align-items: center;
    gap: 0px;
    margin: 5px;
  }
  .icon-play-video {
    font-size: 20px;
  }
  .image-newrelease-container img {
    width: 100px;
  }
}

@media screen and (max-width: 576px) {
  .image-newrelease-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 5px;
  }
  .icon-play-video {
    font-size: 20px;
    
  }
  .image-newrelease-container img {
    width: 40%;
  }
}
@media screen and (max-width: 475px) {
  .image-newrelease-container {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 5px;
  }
  .icon-play-video {
    font-size: 20px;
    margin-left: 50px;
  }
  .image-newrelease-container img {
    width: 25%;
  }
}
@media screen and (max-width: 375px) {
  .image-newrelease-container {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 5px;
  }
  .icon-play-video {
    font-size: 20px;
    margin-left: 20px;
  }
  .image-newrelease-container img {
    width: 30%;
  }
} */
