* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* .award-image-container img {
  width: 100%;
  margin-top: 10%;
} */
.award-image-container {
  background: url("../../public/image/ketanspotify.png") no-repeat;
  width: 100%;
  margin-top: 10%;
  background-position: top;
  background-size: cover;
  height: 700px;
}
.award-header h5 {
  text-align: center;
  margin-top: 50px;
  color: white;
  font-weight: 600;
  font-family: var(--font-family);
  font-size: 2rem;
}
.slick-slide .item span {
  display: block;
  margin-left: 20px;
  color: white;
  margin-top: 30px;
  border: 1px solid rgb(26, 25, 25);
  padding: 20px;
  font-family: var(--font-family);
  border-radius: 10px;
  background-color: rgb(26, 25, 25);
}

.award-section span {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.slick-slide .item span:hover {
  background-color: rgba(255, 255, 255, 0.16);
}
.award-section p {
  margin-bottom: 0;
  font-size: 17px;
  font-weight: 500;
}

.award-image-container iframe {
  margin-top: 35px;
}
.award-containe .award-image-container .TrackListWidget_widgetContainer__E__DD {
  border-radius: 0 !important;
}
.award-image-container {
  position: relative;
}

.award-image-container .spotify {
  width: 30%;
  height: 352px;
  margin-left: auto;
  margin-right: 50px;
}

@media screen and (max-width: 992px) {
  .award-image-container .spotify {
    width: 40%;
    height: 352px;
  }
  .award-image-container {
    margin-top: 100px;
  }
}
@media screen and (max-width: 768px) {
  .award-image-container .spotify {
    width: 50%;
    height: 200px;
  }
  .award-image-container .spotify iframe {
    overflow: scroll;
  }
  .award-image-container {
    margin-top: 90px;
  }
}
@media screen and (max-width: 576px) {
  .award-image-container .spotify {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    height: 352px;
  }
  .award-image-container {
    margin-top: 90px;
    background: none;
    height: 400px;
  }
}
