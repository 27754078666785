* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icons-image {
  color: white;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 12px;
}
.icons-image a {
  color: white;
}
